const firebaseConfig = {
    apiKey: "AIzaSyDqE4sROVG_9XTECfeWtNwTuzuoFSJ8_E4",
    authDomain: "hqmpush.firebaseapp.com",
    projectId: "hqmpush",
    storageBucket: "hqmpush.appspot.com",
    messagingSenderId: "272129312927",
    appId: "1:272129312927:web:e07460fde203120b453aaa",
    measurementId: "G-PPDZN7J39L"
};

export default firebaseConfig;
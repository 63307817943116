const AvatarShapes = () => {
    return <svg height="0" width="0">
        <defs>
            <clipPath id="circle">
                <circle cx="18" cy="18" r="17" fill="none" stroke="red" />
            </clipPath>
            <clipPath id="circle-border">
                <circle cx="22" cy="22" r="20" fill="none" stroke="red" />
            </clipPath>
            <clipPath id="square">
                <path d="M 8 0 L 28 0 C 32 0 36 4 36 8 L 36 28 C 36 32 32 36 28 36 L 8 36 C 4 36 0 32 0 28 L 0 8 C 0 4 4 0 8 0" />
            </clipPath>
            <clipPath id="square-border">
                <path d="M 8 0 L 36 0 C 40 0 44 4 44 8 L 44 36 C 44 40 40 44 36 44 L 8 44 C 4 44 0 40 0 36 L 0 8 C 0 4 4 0 8 0" />
            </clipPath>
            <clipPath id="star">
                <path d="M 16.5382 1.8223 C 17.0012 0.7835 17.2328 0.2642 17.5552 0.1042 C 17.8352 -0.0347 18.164 -0.0347 18.444 0.1042 C 18.7664 0.2642 18.998 0.7835 19.461 1.8223 L 23.149 10.0962 C 23.286 10.4032 23.3544 10.5568 23.4604 10.6744 C 23.554 10.7784 23.6686 10.8616 23.7964 10.9186 C 23.941 10.983 24.1082 11.0006 24.4426 11.0359 L 33.4512 11.9867 C 34.5822 12.1061 35.1476 12.1658 35.3994 12.4229 C 35.618 12.6463 35.7196 12.9591 35.674 13.2684 C 35.6216 13.6244 35.1992 14.005 34.3544 14.7664 L 27.625 20.8308 C 27.3754 21.0558 27.2504 21.1684 27.1714 21.3054 C 27.1014 21.4268 27.0576 21.5614 27.043 21.7006 C 27.0264 21.858 27.0612 22.0224 27.131 22.3514 L 29.0106 31.2128 C 29.2466 32.3254 29.3646 32.8816 29.1978 33.2004 C 29.0528 33.4776 28.7868 33.6708 28.4786 33.723 C 28.1238 33.783 27.6312 33.499 26.646 32.9308 L 18.799 28.4048 C 18.5078 28.2368 18.3622 28.153 18.2074 28.12 C 18.0704 28.091 17.9288 28.091 17.7918 28.12 C 17.637 28.153 17.4914 28.2368 17.2002 28.4048 L 9.3532 32.9308 C 8.3681 33.499 7.8755 33.783 7.5207 33.723 C 7.2125 33.6708 6.9464 33.4776 6.8015 33.2004 C 6.6347 32.8816 6.7527 32.3254 6.9887 31.2128 L 8.8682 22.3514 C 8.938 22.0224 8.9728 21.858 8.9563 21.7006 C 8.9416 21.5614 8.8979 21.4268 8.8279 21.3054 C 8.7488 21.1684 8.6239 21.0558 8.3742 20.8308 L 1.6449 14.7664 C 0.8001 14.005 0.3777 13.6244 0.3252 13.2684 C 0.2796 12.9591 0.3812 12.6463 0.5999 12.4229 C 0.8516 12.1658 1.4171 12.1061 2.5481 11.9867 L 11.5567 11.0359 C 11.8911 11.0006 12.0582 10.983 12.2028 10.9186 C 12.3307 10.8616 12.4452 10.7784 12.5389 10.6744 C 12.6448 10.5568 12.7133 10.4032 12.8502 10.0962 L 16.5382 1.8223 Z" />
            </clipPath>
            <clipPath id="star-border">
                <path d="M 19.6805 2.1685 C 20.2314 0.9324 20.507 0.3144 20.8907 0.124 C 21.2239 -0.0413 21.6152 -0.0413 21.9484 0.124 C 22.332 0.3144 22.6076 0.9324 23.1586 2.1685 L 27.5473 12.0144 C 27.7103 12.3799 27.7917 12.5626 27.9179 12.7025 C 28.0293 12.8263 28.1656 12.9253 28.3177 12.9931 C 28.4898 13.0697 28.6888 13.0907 29.0867 13.1327 L 39.8069 14.2642 C 41.1528 14.4062 41.8256 14.4773 42.1253 14.7833 C 42.3854 15.0491 42.5063 15.4214 42.4521 15.7894 C 42.3897 16.213 41.887 16.6659 40.8817 17.572 L 32.8738 24.7887 C 32.5767 25.0564 32.428 25.1904 32.334 25.3534 C 32.2507 25.4979 32.1985 25.6581 32.1812 25.8237 C 32.1614 26.011 32.2028 26.2067 32.2859 26.5982 L 34.5226 37.1432 C 34.8035 38.4672 34.9439 39.1291 34.7454 39.5085 C 34.5728 39.8383 34.2563 40.0683 33.8895 40.1304 C 33.4673 40.2018 32.8811 39.8638 31.7087 39.1877 L 22.3708 33.8017 C 22.0243 33.6018 21.851 33.5021 21.6668 33.4628 C 21.5038 33.4283 21.3353 33.4283 21.1722 33.4628 C 20.988 33.5021 20.8148 33.6018 20.4682 33.8017 L 11.1304 39.1877 C 9.958 39.8638 9.3718 40.2018 8.9496 40.1304 C 8.5828 40.0683 8.2662 39.8383 8.0938 39.5085 C 7.8953 39.1291 8.0357 38.4672 8.3165 37.1432 L 10.5532 26.5982 C 10.6362 26.2067 10.6777 26.011 10.658 25.8237 C 10.6405 25.6581 10.5885 25.4979 10.5052 25.3534 C 10.411 25.1904 10.2624 25.0564 9.9653 24.7887 L 1.9575 17.572 C 0.9521 16.6659 0.4494 16.213 0.3869 15.7894 C 0.3327 15.4214 0.4536 15.0491 0.7138 14.7833 C 1.0134 14.4773 1.6864 14.4062 3.0323 14.2642 L 13.7525 13.1327 C 14.1504 13.0907 14.3493 13.0697 14.5213 12.9931 C 14.6735 12.9253 14.8098 12.8263 14.9213 12.7025 C 15.0473 12.5626 15.1288 12.3799 15.2917 12.0144 L 19.6805 2.1685 Z" />
            </clipPath>
            <clipPath id="hexagon">
                <path d="M 0.6 18.7711 L 6.6421 31.4729 C 7.5606 32.8512 9.1183 33.6663 10.7744 33.6351 H 25.2611 C 26.9171 33.6663 28.4749 32.8512 29.3933 31.4729 L 35.4355 18.7615 C 36.2461 17.506 36.2461 15.892 35.4355 14.6365 L 29.427 2.1437 C 28.508 0.7795 26.9611 -0.027 25.3164 0.0007 H 18.0153 H 10.7143 C 9.0697 -0.027 7.5227 0.7795 6.6037 2.1437 L 0.6096 14.6557 C -0.1997 15.9071 -0.2035 17.5158 0.6 18.7711 Z" />
            </clipPath>
            <clipPath id="hexagon-border">
                <path d="M 0.714 22.3376 L 7.9041 37.4527 C 8.9971 39.0929 10.8508 40.0629 12.8215 40.0258 H 30.0607 C 32.0314 40.0629 33.8851 39.0929 34.978 37.4527 L 42.1682 22.3262 C 43.1328 20.8321 43.1328 18.9115 42.1682 17.4174 L 35.0181 2.551 C 33.9245 0.9276 32.0837 -0.0321 30.1265 0.0008 H 21.4382 H 12.75 C 10.7929 -0.0321 8.952 0.9276 7.8584 2.551 L 0.7254 17.4403 C -0.2376 18.9295 -0.2421 20.8438 0.714 22.3376 Z" />
            </clipPath>
            <clipPath id="heart">
                <path d="M 18 4.0004 C 14.4012 -0.1937 8.3875 -1.4898 3.8785 2.3507 C -0.6306 6.1912 -1.2655 12.6122 2.2756 17.1544 C 5.2197 20.9308 14.1296 28.8958 17.0498 31.4738 C 17.3764 31.7622 17.5398 31.9064 17.7304 31.963 C 17.8966 32.0124 18.0786 32.0124 18.245 31.963 C 18.4356 31.9064 18.5988 31.7622 18.9256 31.4738 C 21.8458 28.8958 30.7556 20.9308 33.6998 17.1544 C 37.2408 12.6122 36.6834 6.1508 32.0968 2.3507 C 27.5102 -1.4494 21.5988 -0.1937 18 4.0004 ZM 18 6.0004 C 14.4012 1.8063 8.3875 0.5102 3.8785 4.3507 C -0.6306 8.1912 -1.2655 14.6122 2.2756 19.1544 C 5.2197 22.9308 14.1296 30.8958 17.0498 33.4738 C 17.3764 33.7622 17.5398 33.9064 17.7304 33.963 C 17.8966 34.0124 18.0786 34.0124 18.245 33.963 C 18.4356 33.9064 18.5988 33.7622 18.9256 33.4738 C 21.8458 30.8958 30.7556 22.9308 33.6998 19.1544 C 37.2408 14.6122 36.6834 8.1508 32.0968 4.3507 C 27.5102 0.5506 21.5988 1.8063 18 6.0004 Z" />
            </clipPath>
            <clipPath id="heart-border">
                <path d="M 21.42 7.1405 C 17.1374 2.1495 9.9811 0.6071 4.6154 5.1773 C -0.7504 9.7475 -1.5059 17.3885 2.708 22.7937 C 6.2114 27.2877 16.8142 36.766 20.2893 39.8338 C 20.6779 40.177 20.8724 40.3486 21.0992 40.416 C 21.297 40.4748 21.5135 40.4748 21.7115 40.416 C 21.9384 40.3486 22.1326 40.177 22.5215 39.8338 C 25.9965 36.766 36.5992 27.2877 40.1028 22.7937 C 44.3166 17.3885 43.6532 9.6995 38.1952 5.1773 C 32.7371 0.6552 25.7026 2.1495 21.42 7.1405 Z" />
            </clipPath>
            <clipPath id="rhomb">
                <path d="M 26.25 7.35 L 30.45 11.55 C 37.8 18.9 37.8 18.9 30.45 26.25 L 26.25 30.45 C 18.9 37.8 18.9 37.8 11.55 30.45 L 7.35 26.25 C 0 18.9 0 18.9 7.35 11.55 L 11.55 7.35 C 18.9 0 18.9 0 26.25 7.35" />
            </clipPath>
            <clipPath id="rhomb-border">
                <path d="M 32 8.96 L 37.12 14.08 C 46.08 23.04 46.08 23.04 37.12 32 L 32 37.12 C 23.04 46.08 23.04 46.08 14.08 37.12 L 8.96 32 C 0 23.04 0 23.04 8.96 14.08 L 14.08 8.96 C 23.04 0 23.04 0 32 8.96" />
            </clipPath>
            <clipPath id="pacman">
                <path d="M 20.0856 19.1968 l 12.2 12.4106 c -3.1089 2.7914 -7.2053 4.5046 -11.7128 4.5046 c -9.6953 0 -17.556 -7.8607 -17.556 -17.556 s 7.8607 -17.556 17.556 -17.556 c 4.9449 0 9.4042 2.0511 12.595 5.3399 l -13.0821 12.8568 z M 21.2736 6.7906 c -1.2114 0 -2.193 0.9831 -2.193 2.1945 c 0 1.2128 0.9817 2.1945 2.193 2.1945 c 1.2128 0 2.196 -0.9817 2.196 -2.1945 c 0 -1.2114 -0.9831 -2.1945 -2.196 -2.1945 z" />
            </clipPath>
            <clipPath id="pacman-border">
                <path d="M 24.1027 22.8362 l 14.64 14.8927 c -3.7307 3.3497 -8.6464 5.4055 -14.0554 5.4055 c -11.6344 0 -21.0672 -9.4328 -21.0672 -21.0672 s 9.4328 -21.0672 21.0672 -21.0672 c 5.9339 0 11.285 2.4613 15.114 6.4079 l -15.6985 15.4282 z z" />
            </clipPath>
        </defs>
    </svg>
}

export default AvatarShapes;